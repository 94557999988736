import style from './Claim.module.scss';

function Claim(props) {
    return (
        <div className={style.claim}>
            <div className={style.body}>
                <p className={style.title}>You have {props.coin} coins. Push</p>
                <button onClick={props.activeForm} className={style.btn}>CLAIM <img src='/image/claim.png' className={style.img} /></button>
            </div>
        </div>
    )
}

export default Claim;