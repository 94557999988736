import { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import style from './Games.module.scss';

function Games() {

    const [uploaded, setUploaded] = useState(false);

    useEffect(() => {
        const alreadyLoaded = sessionStorage.getItem('alreadyLoadedGames');

        if (!alreadyLoaded) {
            setTimeout(() => {
                setUploaded(true);
                sessionStorage.setItem('alreadyLoadedGames', 'true');
            }, 1600);
        } else {
            setUploaded(true);
        }
    }, []);

    return (
        <>
            <div className={style.wrapper}>
                <Header />
                <main className={style.main}>
                    {uploaded ?
                        <div className={style.blocks}>
                            <div className={style.block}>
                                <a href="https://alphatest-games.coin.moi/" target="_blank" rel="noopener noreferrer">
                                    <img src="/image/AlphaTest.webp" alt="Alpha Test" className={style.image} />
                                </a>
                            </div>
                            <div className={style.block}><p>coming soon</p></div>
                            <div className={style.block}><p>coming soon</p></div>
                            <div className={style.block}><p>coming soon</p></div>
                            <div className={style.block}><p>coming soon</p></div>
                            <div className={style.block}><p>coming soon</p></div>
                        </div>
                        : (
                            <section className={style.loading}>
                                <div className={style.body}>
                                    <p className={style.title}>Loading ...</p>
                                    <div className={style.shell}>
                                        <span>LOADING</span>
                                        <div className={style.load}></div>
                                    </div>
                                </div>
                            </section>
                        )}
                </main>
                <Footer />
            </div >
        </>
    )
}

export default Games;
