import { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import GlitchAnimation from '../../components/GlitchAnimation/GlitchAnimation';
import Menu from '../../components/Header/Header';
import style from './Special.module.scss';

function Special() {
    const arraySpecial = [
        {
            special: 'Discord',
            number: '1',
            links: [
                { text: 'Main Server', url: 'https://discord.gg/9BKEdcZKdH' }

            ],
            isActive: false
        },
        {
            special: 'Twitter',
            number: '2',
            links: [
                { text: 'Main account', url: 'https://x.com/coin_moi' }

            ],
            isActive: false
        },
        {
            special: 'GitHub',
            number: '3',
            links: [
                { text: 'Main Github', url: 'https://github.com/moirai-project' }

            ],
            isActive: false
        },
        {
            special: 'Wallets',
            number: '4',
            links: [
                { text: 'Ubuntu 20', url: 'https://github.com/moirai-project/moirai/releases/download/v1.0.0/moirai-bin-ubuntu-20-linux-x86-64.tar.gz' },
                { text: 'Ubuntu 22', url: 'https://github.com/moirai-project/moirai/releases/download/v1.0.0/moirai-bin-ubuntu-22--linux-x86-64.tar.gz' },
                { text: 'Ubuntu 23', url: 'https://github.com/moirai-project/moirai/releases/download/v1.0.0/moirai-bin-ubuntu-23-linux-x86-64.tar.gz' },
                { text: 'Windows', url: 'https://github.com/moirai-project/moirai/releases/download/v1.0.0/moirai-bin-windows-86x64.zip' },
                { text: 'Web Wallet (Soon)', url: '#' }
            ],
            isActive: false
        },
        {
            special: 'Explorer',
            number: '5',
            links: [
                { text: 'Explorer', url: 'https://explorer.coin.moi' }

            ],
            isActive: false
        },
        {
            special: 'Pools',
            number: '6',
            links: [
                { text: 'Official pool', url: 'https://pool.coin.moi' },
				{ text: 'p2pool', url: 'https://p2pool.pw/#moi' },
				{ text: 'luckydogpool', url: 'https://luckydogpool.com' },
				{ text: 'altcoinspool', url: 'https://altcoinspool.com/' },
				{ text: 'miningws', url: 'https://miningws.online/' }

            ],
            isActive: false
        },
        {
            special: 'Exchange',
            number: '7',
            links: [
                { text: 'Bitcointry', url: 'https://bitcointry.com/' }

            ],
            isActive: false
        }
    ];

    const [activeArraySpecial, setActiveArraySpecial] = useState(arraySpecial);
    const [currentSpecial, setCurrentSpecial] = useState(null);
    const [launchAnimation, setLaunchAnimation] = useState(false);

    const handleClick = (special) => {
        const newArraySpecial = arraySpecial.map((el) => ({
            ...el, isActive: el.special === special.special ? true : false
        }));
        setActiveArraySpecial(newArraySpecial);
        setCurrentSpecial(special);
    }

    useEffect(() => {
        setLaunchAnimation(true);
        setTimeout(() => {
            setLaunchAnimation(false);
        }, 1100);
    }, []);

    return (
        <>
            <div className={style.wrapper}>
                <GlitchAnimation />
                <Menu />
                <main style={launchAnimation ? { overflow: 'hidden' } : { overflow: 'auto' }}>
                    <section className={style.specials}>
                        <div className={style.container}>
                            <div className={style.body}>
                                <div className={`${style.list} ${launchAnimation && `${style.animation__launch__list}`}`}>
                                    {activeArraySpecial.map((item) => (
                                        <div key={item.special} onClick={() => handleClick(item)} className={style.shell}>
                                            <div>
                                                <p className={item.isActive && style.active}>{item.special}</p>
                                            </div>
                                            <span>{item.number}</span>
                                        </div>
                                    ))}
                                </div>
                                {currentSpecial ?
                                    <div className={`${style.description} ${launchAnimation && `${style.animation__launch__description}`}`}>
                                        <h3 className={style.title}>{currentSpecial.special}</h3>
                                        <div className={style.subtitle}>
                                            {currentSpecial.links.map((link, index) => (
                                                <p key={index}><a href={link.url} target="_blank" rel="noopener noreferrer">{link.text}</a></p>
                                            ))}
                                        </div>
                                    </div>
                                    : <div className={`${style.description} ${launchAnimation && `${style.animation__launch__description}`}`}><h3 className={style.title}>Information links</h3></div>
                                }
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </>
    )
}

export default Special;
