import { useEffect, useState } from 'react';
import style from './Greetings.module.scss';
import GlitchAnimation from '../../components/GlitchAnimation/GlitchAnimation';
import Loading from '../../components/Loading/Loading';
import DustAnimation from '../../components/DustAnimation/DustAnimationDustAnimation';

function Greetings(props) {

    const [active, setActive] = useState(false);
    const [startup, setStartup] = useState(false);

    useEffect(() => {
        if (window.screen.width > 1000) {
            setActive(true);
        } else {
            setTimeout(() => {
                setActive(true);
            }, 500);
        }
    }, []);

    const handleClickButton = () => {
        setStartup(true);
        if (window.screen.width > 1000) {
            props.setStarted(true);
        } else {
            setInterval(() => {
                props.setStarted(true);
            }, 900);
        }
    }

    return (
        <div className={style.greetings}>
            {props.started
                ? <Loading setFirstGreeting={props.setFirstGreeting} />
                : (window.screen.width > 1000
                    // pc
                    ?
                    <>
                        <DustAnimation />
                        <div className={`${style.pc} ${active ? `${style.zoomed}` : ''} ${startup && `${style.startup}`}`}>
                            <button onClick={handleClickButton} className={style.btn}>PUSH</button>
                        </div>
                    </>
                    // mobile
                    :
                    <div className={style.mobile}>
                        <GlitchAnimation />
                        <div className={`${style.body} ${startup && `${style.animation__open}`}`}>
                            <div className={`${style.line__top} ${active && `${style.animation__transform__top}`}`}></div>
                            <h1 className={`${style.title} ${active && `${style.animation__title}`}`}>VAULE-TEC</h1>
                            <button onClick={handleClickButton} className={style.btn}>PUSH</button>
                            <p className={`${style.subtitle} ${active && `${style.animation__opacity}`}`}>ROBCO INDUSTRIES UNIFIED OPERATIMG SYSTEM COPYRIGHT 2075-2077 ROBCO INDUSTRIES</p>
                            <div className={`${style.line__bottom} ${active && `${style.animation__transform__bottom}`}`}></div>
                        </div>
                    </div>)
            }
        </div>
    )
}

export default Greetings;
