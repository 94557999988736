import React, { useState, useEffect, useRef } from 'react';
import style from './Form.module.scss';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

function Form(props) {
    const [address, setAddress] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [loadingCaptcha, setLoadingCaptcha] = useState(false);
    const [captchaCode, setCaptchaCode] = useState('');

    const addressRef = useRef('');
    const fingerprintRef = useRef('');

    useEffect(() => {
        const getFingerprint = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            fingerprintRef.current = result.visitorId;
        };
        getFingerprint();
    }, []);

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
        addressRef.current = e.target.value;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (!addressRef.current) {
            setError('Please enter an address.');
            return;
        }

        setLoadingCaptcha(true);

        setTimeout(() => {
            setLoadingCaptcha(false);
            setShowCaptcha(true);
        }, 1000);
    };

    const handleCaptchaSolve = async (captchaResponse) => {
        setCaptchaCode(captchaResponse);
        setShowCaptcha(false);
        
        const currentAddress = addressRef.current;
        const currentFingerprint = fingerprintRef.current;

        setIsSubmitting(true);

        try {
            const response = await fetch('https://coin.moi/wallet/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Browser-Fingerprint': currentFingerprint,
                    'Captcha-Code': captchaResponse,
                },
                body: JSON.stringify({ address: currentAddress, amount: props.coin }),
            });

            if (response.status === 429) {
                setError('You have already tried to claim the bonus, please try again later.');
            } else if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            } else {
                const data = await response.json();
                if (data.result) {
                    setTransactionId(data.result.trim());
                }
            }
        } catch (error) {
            setError('Error sending data.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const onCaptchaSolve = (event) => {
        if (event.origin === 'https://coin.moi') {
            handleCaptchaSolve(event.data);
        }
    };

    useEffect(() => {
        window.addEventListener('message', onCaptchaSolve);
        return () => window.removeEventListener('message', onCaptchaSolve);
    }, []);

    return (
        <div className={style.shell}>
            {transactionId ? (
                <h2 className={style.transaction}>
                    Transaction ID: 
                    <a 
                        href={`https://explorer.coin.moi/tx/${transactionId}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className={style.idLink}
                    >
                        {transactionId}
                    </a>
                </h2>
            ) : (
                <div>
                    <form className={style.form} onSubmit={handleSubmit}>
                        <input
                            name="address"
                            placeholder="Enter address"
                            value={address}
                            onChange={handleAddressChange}
                        />
                        <button type="submit" disabled={isSubmitting || !fingerprintRef.current || !addressRef.current}>
                            Send
                        </button>
                        {error && <div className={style.error}>{error}</div>}
                    </form>
                    
                    {loadingCaptcha && <div className={style.loadingMessage}>Loading captcha...</div>}

                    {showCaptcha && (
                        <div className={style.captchaModal}>
                            <div className={style.form}>
                                <iframe
                                    src="https://coin.moi/captcha"
                                    frameBorder="0"
                                    title="CAPTCHA"
                                    width="130%"
                                    height="170px"
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Form;

