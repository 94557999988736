import About from "./page/About/About";
import Games from "./page/Games/Games";
import Home from "./page/Home/Home";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Links from "./page/Links/Links";
import Special from "./page/Special/Special";
import Frame from "./components/Frame/Frame";

function App() {
  return (
    <>
      <Frame>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/special" element={<Special />} />
            <Route path="/links" element={<Links />} />
            <Route path="/games" element={<Games />} />
          </Routes>
        </BrowserRouter>
      </Frame>
    </>
  );
}

export default App;
