import React from 'react';
import { useSpring, animated } from 'react-spring';

const GlitchAnimation = () => {
    const lineAnimationProps = useSpring({
        from: { top: '0%', opacity: 0.5 },
        to: { top: '98%', opacity: 0.7 },
        config: { duration: 6000 },
        reset: true,
        loop: true
    });

    return (
        <animated.div
            style={{
                position: 'absolute',
                width: '100%',
                height: '13px',
                backgroundColor: '#272b23',
                backgroundImage: 'repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4) 2px, transparent 3px, transparent 3px)',
                top: '0',
                left: '0',
                zIndex: '-1',
                ...lineAnimationProps
            }}
        />
    );
};

export default GlitchAnimation;

