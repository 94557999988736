import style from './Frame.module.scss';
import GlitchAnimation from '../../components/GlitchAnimation/GlitchAnimation';
import { useEffect, useState } from 'react';
import Greetings from '../../page/Greeting/Greetings';

function Frame({ children }) {
    const [firstGreeting, setFirstGreeting] = useState(false);
    const [launch, setLaunch] = useState(false);
    const [started, setStarted] = useState(false);

    useEffect(() => {
        const firstGreeting = sessionStorage.getItem('firstGreeting');

        if (!firstGreeting) {
            setFirstGreeting(true);
            setLaunch(true);
            sessionStorage.setItem('firstGreeting', 'true');
        } else {
            setFirstGreeting(false);
            setStarted(true);
        }
    }, []);


    return (
        <>
            <div className={style.monitor}>
                <GlitchAnimation />
                {window.screen.width > 1000
                    ? <div className={style.frame}>
                        <img src='/image/monitor-b.webp' className={`${style.img}  ${started && `${style.started}`} ${launch && `${style.zoomed}`}`} />
                    </div>
                    : (<div className={style.frame}>
                        <div className={style.frame__one}> <div className={style.frame__two}></div></div>
                    </div>)}
                {firstGreeting
                    ? <Greetings setFirstGreeting={setFirstGreeting} setStarted={setStarted} started={started} />
                    : <div className={style.screen}>
                        {children}
                    </div>
                }
            </div>
        </>
    )
}

export default Frame;