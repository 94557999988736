import { useEffect, useState } from 'react';
import style from './Loading.module.scss';

function Loading(props) {
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (window.screen.width > 1000) {
            setTimeout(() => {
                setActive(true);
            }, 550);
        } else {
            setActive(true);
        }
    }, []);

    useEffect(() => {
        if (active) {
            setTimeout(() => {
                props.setFirstGreeting(false);
            }, 2000)
        }
    }, [active])

    return (
        <>
            {active && (<div className={style.loading}>
                <div className={style.line__top}></div>
                <div className={style.body}>
                    <h2 className={style.title}>Loading ...</h2>
                    <div className={style.shell}>
                        <span>LOADING</span>
                        <div className={style.load}></div>
                    </div>
                </div>
                <div className={style.line__bottom}></div>
            </div>)
            }

            {/* <div className={style.loading}>
                <div className={style.line__top}></div>
                <div className={style.body}>
                    <div className={style.text}>
                        <h6 className={style.title}>Pip-boy 3000</h6>
                        <p className={style.subtitle}>Robco Industries</p>
                    </div>
                    <img src='./image/pngwing.com (4).png' className={style.img} />
                    <div className={style.shell}>
                        <span>LOADING</span>
                        <div className={style.load}></div>
                    </div>
                </div>
                <div className={style.line__bottom}></div>
            </div> */}
        </>
    )
}

export default Loading;