import Footer from '../../components/Footer/Footer';
import Frame from '../../components/Frame/Frame';
import GlitchAnimation from '../../components/GlitchAnimation/GlitchAnimation';
import Header from '../../components/Header/Header';
import style from './About.module.scss';

function About() {
    return (
        <>
            <div className={style.wrapper}>
                <Header />
                <main>
                    <div className={style.container}>
                        <h1 className={style.title}>About Moirai</h1>
                        <div className={style.spacer}></div>
                        <p>
                            The Moirai project was created to provide a unique platform for gaming and entertainment using the advanced Scrypt algorithm. We aim to create a system that goes beyond the traditional capabilities of cryptocurrencies, offering users fast, secure and decentralized solutions for the gaming industry.
                        </p>
                        <p>
                            Moirai is inspired by the idea of "silver," which has historically been considered less valuable than gold but more widely used as a medium of exchange. In this context, Moirai strives to be not only a reliable store of value but also an active participant in everyday financial transactions, especially in the field of digital entertainment.
                        </p>
                        <p>
                            The consensus algorithm of Moirai is designed to address the key challenges of the gaming industry: transaction confirmation speed and transparency. We believe that instant transaction confirmation is critically important for improving user experience and integration with other web applications. Moirai optimizes for minimizing delays, ensuring fast and reliable transaction processing.
                        </p>
                        <p>
                            Moirai also offers innovative mechanisms to protect users from manipulation, ensuring transparency and fairness in the gaming process.
                        </p>
                        <p>
                            The Moirai project combines advanced technologies and decentralized principles, offering unique opportunities for the gaming industry. We believe that Moirai can meet the growing demand for secure and efficient solutions in the world of digital entertainment, providing users with a reliable platform for gaming and value exchange.
                        </p>
                    </div>
                </main>
                <Footer />
            </div>
        </>
    )
}

export default About;
