import style from './Footer.module.scss';

function Footer() {
    return (
        <footer className={style.footer}>
            <div className={style.line}></div>
        </footer>
    )
}

export default Footer;