import { Link } from 'react-router-dom';
import style from './Header.module.scss';
import { useEffect, useState } from 'react';

function Header() {
    const [menuItems, setMenuItems] = useState([
        { label: 'HOME', link: '/', isActive: false },
        { label: 'ABOUT', link: '/about', isActive: false },
        { label: 'SPECIAL', link: '/special', isActive: false },
        { label: 'LINK', link: '/links', isActive: false },
        { label: 'GAMES', link: '/games', isActive: false },
    ]);
    const [burgerToggle, setBurgerToggle] = useState(false);

    useEffect(() => {
        const currentPath = window.location.pathname;
        const updatedMenuItems = menuItems.map(item => ({
            ...item, isActive: item.link === currentPath,
        }));
        setMenuItems(updatedMenuItems);
        console.log(menuItems);
    }, []);

    return (
        <header className={style.header}>
            <div className={style.burger}>
                <h1 className={style.logo}>PipBoy</h1>
                <button className={burgerToggle ? `${style.btn__active} ${style.btn}` : `${style.btn} ${style.btn__remove}`} onClick={() => setBurgerToggle(!burgerToggle)}><span></span></button>
            </div>
            <nav className={burgerToggle ? `${style.active__menu} ${style.body}` : style.body}>
                <ul className={style.list}>
                    {menuItems.map(item => (
                        <li key={item.label} className={item.isActive ? `${style.link} ${style.active__link}` : style.link}>
                            <Link to={item.link}>{item.label}</Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </ header >
    )
}

export default Header;